
import { Component, Vue } from "vue-property-decorator";
import * as BlogService from '@/services/BlogServices';
import { ROOT_ACTIONS } from "@/store/actions";


@Component({
    components: {

    }
})
export default class Index extends Vue {
    blogs = [] as any[];
    async mounted() {
        this.blogs = await BlogService.ListBlogs();
    }

    newName = '';
    newBlogDialog = false;
    newBlogLoading = false;

    get newBlogId() {
        let urlFriendlyTitle = this.newName.toLowerCase().trim();

        // Replace spaces with hyphens
        urlFriendlyTitle = urlFriendlyTitle.replace(/\s+/g, '-');

        // Remove special characters using regular expressions
        urlFriendlyTitle = urlFriendlyTitle.replace(/[^a-z0-9-]/g, '');

        return urlFriendlyTitle;
    }

    async saveNewBlog() {
        try {
            this.newBlogLoading = true;
            await BlogService.CreateBlog({
                id: this.newBlogId,
                title: this.newName
            });

            this.newBlogDialog = false;
            this.blogs = await BlogService.ListBlogs();
        } catch (error) {
            this.$store.dispatch(ROOT_ACTIONS.TOGGLE_NOTIFICATION, {
                color: 'error',
                text: 'Er is iets misgegaan bij het opslaan van de blogpost'
            })
        } finally {
            this.newBlogLoading = false;
        }

    }

    async pinArticle(blogId: string) {
        try {
            await BlogService.UpdateBlog(blogId, {
                pinned: true
            });
            this.blogs = await BlogService.ListBlogs();
        } catch (error) {
            this.$store.dispatch(ROOT_ACTIONS.TOGGLE_NOTIFICATION, {
                color: 'error',
                text: 'Er is iets misgegaan bij het pinnen van de blogpost'
            })
        }
    }

}
